import { defineStore } from 'pinia';

export const useModalErrorStore = defineStore('modal-error-store', () => {
  const message = ref(false);

  const showError = (payload) => {
    message.value = payload;
  };

  const hideError = () => {
    message.value = false;
  };

  
  return { message, showError, hideError };
  
});
  