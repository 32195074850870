<template>
    <div class="overlay-modal" @click.prevent="closeModal()" v-if="modalErrorStore.message">
        <div class="modal-content" >
            <div class="row">
                <div class="col-6 text-start">
                    <h4>Error</h4>
                </div>
                <div class="col-6 text-end">
                    <a class="cursor-pointer" @click.prevent="closeModal()"><span class="icon-times text--tertiary"></span></a>
                </div>
                <div class="col-12 py-3 text-center modal-body">
                   {{modalErrorStore.message}}
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">

import { useModalErrorStore } from '@/store/modal-error';

const modalErrorStore = useModalErrorStore();

const closeModal = () => {
  modalErrorStore.hideError();
};

</script>
<style lang="scss" scoped>
.overlay-modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(black, 0.8);
    z-index: 1001;
    display:flex;
    justify-content:center;
    align-items:center;

    .modal-content{
        background:white;
        max-width:80%;
        width:600px;
        padding:20px;
        border-radius:5px;
        box-shadow:0px 0px 2px rgba(black,.8)

       
    }
}

</style>
