<template>
    <div class="overlay" v-if="spinnerStore.show">
        <div class="spinner" >
            <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">

import { useSpinnerStore } from '@/store/spinner';

const spinnerStore = useSpinnerStore();

</script>
<style lang="scss" scoped>
.overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(black, 0.8);
    z-index: 1000;
    pointer-events: none;
    display:flex;
    justify-content:center;
    align-items:center;
}

.spinner {
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid var(--quaternary-20);
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: var(--quaternary-20) transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
